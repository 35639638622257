Webflow.push(function() {
  // Hover text change
  $('[hover-text]').hover(function() {
    var el = $(this); el.attr('hover-original', el.text()).text(el.attr('hover-text'));
  }, function() {
    var el = $(this); el.text(el.attr('hover-original'));
  });

  if(!$('html').hasClass('w-mod-touch')){
    $('.footer-text-content a[href*="tel"]').mouseenter(function(){
      var tel = $(this).attr('href').split('tel:')[1];
      $(this).text(tel);
    }).mouseleave(function(){
      $(this).text('call');
    });
  }

  if($('.page-project').length==0 && !$('body').hasClass('home') && !$('body').hasClass('no-footer-scroll')){
    $(window).scroll(function(){
      if(window.innerWidth >= 991) {
        var scrollPeek = window.innerHeight*.15;

        if(!window.preventScroll){
          var offset = 0;
          let footerRect = $('.section.footer').get(0).getBoundingClientRect();
          var scrollTo = null;

          var dir = -1;
          if(window.prevScroll < $(window).scrollTop()){
            dir = 1; // down
          }


          if(footerRect.top-window.innerHeight < -scrollPeek && footerRect.top > scrollPeek){
            scrollTo = $('.section.footer');
            if(dir==-1){
              offset = -window.innerHeight;
              // scrollTo = $('body');
              // scrollTo = $('.hero-wrap');
            }
          }

          if(scrollTo){
            $('html').css('overflow-y','hidden');
            window.preventScroll = true;
            $('html').stop().animate({'scrollTop': $(scrollTo).position().top + offset}, 600, function(){
              clearTimeout(window.endScroll);
              window.endScroll = setTimeout(function(){
                $('html').css('overflow-y','scroll');
                window.preventScroll = false;
              }, 200);
              console.log('done');
            });
          }
        } else {
          clearTimeout(window.endScroll);
          window.endScroll = setTimeout(function(){
            $('html').css('overflow-y','scroll');
            window.preventScroll = false;
          }, 200);
        }
        window.prevScroll = $(window).scrollTop();
      }
    });
  }

  // Menu click "Menu" text
  $('a.menu').unbind('click').click(function(){
    var curT = $('.breadcrumb-title').first().text();
    var newT = 'Menu';
    if(curT!='Menu') {
      $('.menu-wrap > a, .menu-footer').css({
        'opacity' : 0,
        'transform' : 'translateY(2rem)',
      });
      $('html').css('overflow','hidden');
      $('.breadcrumb-title').attr('data-prev', curT);
      $('.breadcrumb-wrap').css({
        'z-index' : 6,
        'pointer-events' : 'none',
      });

      $('.menu-wrap > a, .menu-footer').each(function(idx){
        var t = this;
        setTimeout(function(){
          $(t).css({
            'opacity' : 1,
            'transform' : '',
          });
        }, 200 + 120 * idx+1);
      });
    } else {
      $('html').css('overflow','');
      newT = $('.breadcrumb-title').attr('data-prev');
      $('.breadcrumb-wrap').css({
        'z-index' : '',
        'pointer-events' : '',
      });
    }
    $('.breadcrumb-title').first().text(newT);
  });

  $('#letstalklink, #letstalkclose').unbind('click').click(function(e){
    e.preventDefault();
    var toggled = $('#letstalklink').attr('data-open');
    var openLetsTalk = !toggled||toggled=='false' ? true : false;
    if(openLetsTalk) {
      // $('header.header').css('z-index',4);
      $('a.menu').fadeOut(200);
      $('div.lets-talk').css('transform','translate3d(0%,0%,0)');
      $('#letstalklink').attr('data-open', 'true');
      $('#letstalkclose').css('opacity',1);
      $('.menu-wrap > a:not(#letstalklink), .menu-footer').addClass('disabled');
    } else {
      // $('header.header').css('z-index',5);
      $('a.menu').fadeIn(200);
      $('div.lets-talk').css('transform','translate3d(100%,0%,0)');
      $('#letstalklink').attr('data-open', 'false');
      $('#letstalkclose').css('opacity',0);
      $('.menu-wrap > a:not(#letstalklink), .menu-footer').removeClass('disabled');
    }
  });

  /*
  $('.lets-talk-person-content').hide();
  // $('.lets-talk-person-link').hide();
  $('.lets-talk-person').unbind('mouseenter mouseleave').mouseenter(function(){
    $(this).find('.lets-talk-person-link').stop().slideDown(200);
  }).mouseleave(function(){
    $(this).find('.lets-talk-person-link').stop().slideUp(200);
  });
  */

  /*
  $('.lets-talk-person').unbind('click').click(function(e){
    e.preventDefault();
    var a = $(this).attr('data-link');
    var t = $(this).attr('data-name');
    if($('.lets-talk-meet-button').attr('href') == a) {
        $('.lets-talk-person').css('background-color','transparent');
        $('.lets-talk-meet-button')
            .attr('href','#')
            .text('Pick a person to meet')
            .css({
                'opacity' : '0.6',
                'cursor' : 'not-allowed',
            });
    } else {
        $('.lets-talk-person').css('background-color','transparent');
        $(this).css('background-color','#1a1a1a');
        $('.lets-talk-meet-button')
          .attr('href',a)
          .text('Book a meeting with '+t+' →')
          .css({
            'opacity' : 1,
            'cursor' : 'pointer',
          });
    }
  });
  */


  // UL LIs to counters

  $('ul li').each(function(idx){
    var h = $(this).html().replace(/[\u200B-\u200D\uFEFF]/g, '');
    if(h.indexOf('<strong>') == 0) {
      var numText = $(this).children().first().text().trim();
      var numParsed = numText.replaceAll(' ','');
      // var numParsed = numText.replaceAll(' ','').replaceAll(',','').replaceAll('.','');
      var regex = /^\d+$/;
      //var regex = /^(?!0+\.00)(?=.{1,9}(\.|$))(?!0(?!\.))\d{1,3}(,\d{3})*(\.\d+)?$/;
      let isNum = regex.test(numParsed);
      console.log(numText, isNum);
      if(isNum) {
        $(this).addClass('stats-container');
        var finalString = '';
        var numString = "<span class='stat-number' data-tar='"+numParsed+"'>0</span>";
        var end = h.split('</strong>')[1];
        if(h.indexOf('<br>')<0){
          finalString = '<p class="signifier-84-47">'+numString+'</p><p class="apk-20">'+end+'</p>';
        } else {
          var lines = end.split('<br>');
          if(lines.length==0){
            finalString = '<p class="signifier-84-47">'+numString+'</p><p class="apk-20">'+lines[0]+'</p>';
          } else if(lines.length>1) {
            finalString = '<p class="signifier-84-47">'+numString+'<span class="apk-20">'+lines[0]+'</span></p>';
            for(var i=1;i<lines.length;i++){
              finalString += '<p class="apk-20">'+lines[i]+'</p>';
            }
          }

        }
        $(this).html(finalString);
        console.log(finalString);
        $(this).parents('ul').addClass('counter-ul');
      } else {
        var finalString = '';
        var numString = "<span class='stat-numberz'>"+numText+"</span>";
        var end = h.split('</strong>')[1];

        if(h.indexOf('<br>')<0){
          finalString = '<p class="signifier-84-47">'+numString+'</p><p class="apk-20">'+end+'</p>';
        } else {
          var lines = end.split('<br>');
          if(lines.length==0){
            finalString = '<p class="signifier-84-47">'+numString+'</p><p class="apk-20">'+lines[0]+'</p>';
          } else if(lines.length>1) {
            finalString = '<p class="signifier-84-47">'+numString+'<span class="apk-20">'+lines[0]+'</span></p>';
            for(var i=1;i<lines.length;i++){
              finalString += '<p class="apk-20">'+lines[i]+'</p>';
            }
          }

        }
        $(this).html(finalString);
        $(this).parents('ul').addClass('counter-ul');

      }
    }
  }); // end ul li counters


  $('.counter-ul').each(function(){
    if($(this).find('li').length < 3) {
      $(this).addClass('not-full');
    }
  });


  // stat number counter
  window.rafs = [];
  if(!$('body').hasClass('home') && $('.stat-number').length>0){

    $(window).scroll(function(){
    $('.stat-number:not(.doing):not(.done)').each(function(idx){
      if(this.getBoundingClientRect().top < window.innerHeight*.8) {
        var tar = parseInt($(this).attr('data-tar'));
        var plus = Math.ceil(tar/60);
        $(this).addClass('doing');
        //setTimeout(function(){
          window.rafs.push({
            el: this,
            num: tar,
            plus: plus,
          });
          cancelAnimationFrame( window.counterRaf );
          requestAnimationFrame( window.counterRaf );
        //}, idx*200 + 400);
      } // end if < winy*.8
    }); // end each
  });

  $('.stat-number:not(.doing):not(.done)').each(function(){
    if(!$(this).attr('data-tar')) {
      $(this).attr('data-tar', parseInt($(this).text()));
    }
    $(this).text('0');
  });

  // end stat number counter
  } // end if has rafs or is not home

  window.counterRaf = function(){
    if(window.rafs.length>0){
      requestAnimationFrame( window.counterRaf );
      for(var i=0;i<window.rafs.length;i++){
        let el = window.rafs[i].el;
        let tar = parseInt(window.rafs[i].num);
        let plus = parseInt(window.rafs[i].plus);
        let cur = parseInt($(el).text().replaceAll(',',''));
        if(cur<=tar){
          let val = cur+plus;
          val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          // el.innerText = val;
          $(el).text(val);
        } else {
          let val = tar;
          val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          $(el).text(val);
          // el.innerText = val;
          $(el).addClass('done');
          //$(el).text(tar).addClass('done').removeClass('doing');
          window.rafs.splice(i, 1);
        }
      }
    }
  } // end counterRaf


$('.modal-footer a[href="#talk"]').off('click').on('click', function(e){
  e.preventDefault();
  $('.modal').removeClass('in');
  $('.modal-level-two').removeClass('in');
  setTimeout(function(){
    $('.tabs-section-v2').animate({
      'scrollTop' : $('.tabs-section-v2').get(0).scrollHeight-window.innerHeight}, 600);
    }, 200);
  });
});
